// module.exports = function (IndexArrayNumber) {
//Get the sign array index number of the sign. Pass in the Month and day.;
// import {months} from '../Public/BOSCalendar2021.json';
// import {months} from '../assets/Public/BOSSignProfiles.json';

exports.signArrayIdIndex = (monthArrayID, selectedDayValue, monthData) => {

    // const date = new Date();  // 2009-11-10
    // const monthArrayID = date.toLocaleString('default', { month: 'long' });

  //  selectedDayValue = 2;
  let months = monthData;
  console.log('calc monthArrayID/Name, selectedDayValue, months', monthArrayID, selectedDayValue, months);
  switch (monthArrayID) {

    case 'January':
      // identifySign(monthArrayID, selectedDayValue, 9, 10);

      let startSignIndex000 = 9;
      let endSignIndex000 = 10;


      if (months[0].transferDate < selectedDayValue) {
        // console.log('current end SignInDex0 !' + endSignIndex0);

        let signID = endSignIndex000;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[0].transferDate) {
        // console.log('current Start SignInDex0 !' + startSignIndex0);

        let signID = startSignIndex000;
        // signArrayIdIndex(signID);
        return signID;
      }

      break;
      // return

    case 'February':
      // identifySign(monthArrayID, selectedDayValue, 10, 11);
      let startSignIndex001 = 10;
      let endSignIndex001 = 11;
      if (months[1].transferDate < selectedDayValue) {
        // console.log('current end SignInDex1 !' + endSignIndex1);

        let signID = endSignIndex001;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[1].transferDate) {
        // console.log('current Start SignInDex1 !' + startSignIndex1);

        let signID = startSignIndex001;
        // signArrayIdIndex(signID);
        return signID;
      }

      break;
      // return;

    case 'March':
      // identifySign(monthArrayID, selectedDayValue, 11, 0);
      let startSignIndex002 = 11;
      let endSignIndex002 = 0;
      if (months[2].transferDate < selectedDayValue) {
        // console.log('current end SignInDex2 !' + endSignIndex2);

        let signID = endSignIndex002;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[2].transferDate) {
        // console.log('current Start SignInDex2 !' + startSignIndex2);

        let signID = startSignIndex002;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'April':
      // identifySign(monthArrayID, selectedDayValue, 0, 1);
      let startSignIndex003 = 0;
      let endSignIndex003 = 1;
      if (months[3].transferDate < selectedDayValue) {
        // console.log('current end SignInDex3 !' + endSignIndex3);

        let signID = endSignIndex003;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[3].transferDate) {
        // console.log('current Start SignInDex3 !' + startSignIndex3);

        let signID = startSignIndex003;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'May':
      // identifySign(monthArrayID, selectedDayValue, 1, 2);
      let startSignIndex004 = 1;
      let endSignIndex004 = 2;
      if (months[4].transferDate < selectedDayValue) {
        // console.log('current end SignInDex4 !' + endSignIndex4);

        let signID = endSignIndex004;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[4].transferDate) {
        // console.log('current Start SignInDex !4' + startSignIndex4);

        let signID = startSignIndex004;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'June':
      // identifySign(monthArrayID, selectedDayValue, 2, 3);
      let startSignIndex005 = 2;
      let endSignIndex005 = 3;
      if (months[5].transferDate < selectedDayValue) {
        // console.log('current end SignInDex5 !' + endSignIndex5);

        let signID = endSignIndex005;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[5].transferDate) {
        // console.log('current Start SignInDex5 !' + startSignIndex5);

        let signID = startSignIndex005;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'July':
      // identifySign(monthArrayID, selectedDayValue, 3, 4);
      let startSignIndex006 = 3;
      let endSignIndex006 = 4;
      if (months[6].transferDate < selectedDayValue) {
        // console.log('current end SignInDex 006!' + endSignIndex006);

        let signID = endSignIndex006;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[6].transferDate) {
        // console.log('current Start SignInDex006 !' + startSignIndex006);

        let signID = startSignIndex006;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'August':
      // identifySign(monthArrayID, selectedDayValue, 4, 5);
      let startSignIndex007 = 4;
      let endSignIndex007 = 5;
      if (months[7].transferDate < selectedDayValue) {
        // console.log('current end SignInDex7 !' + endSignIndex7);

        let signID = endSignIndex007;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[7].transferDate) {
        // console.log('current Start SignInDex7 !' + startSignIndex7);

        let signID = startSignIndex007;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'September':
      // identifySign(monthArrayID, selectedDayValue, 5, 6);
      let startSignIndex008 = 5;
      let endSignIndex008 = 6;
      if (months[8].transferDate < selectedDayValue) {
        // console.log('current end SignInDex8 !' + endSignIndex8);

        let signID = endSignIndex008;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[8].transferDate) {
        // console.log('current Start SignInDex8 !' + startSignIndex8);

        let signID = startSignIndex008;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'October':
      // identifySign(monthArrayID, selectedDayValue, 6, 7);
      let startSignIndex009 = 6;
      let endSignIndex009 = 7;
      if (months[9].transferDate < selectedDayValue) {
        // console.log('current end SignInDex9 !' + endSignIndex9);

        let signID = endSignIndex009;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[9].transferDate) {
        // console.log('current Start SignInDex9 !' + startSignIndex9);

        let signID = startSignIndex009;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'November':
      // identifySign(monthArrayID, selectedDayValue, 7, 8);
      let startSignIndex0010 = 7;
      let endSignIndex0010 = 8;
      if (months[10].transferDate < selectedDayValue) {
        // console.log('current end SignInDex10 !' + endSignIndex10);

        let signID = endSignIndex0010;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[10].transferDate) {
        // console.log('current Start SignInDex10 !' + startSignIndex10);

        let signID = startSignIndex0010;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 'December':
      // identifySign(monthArrayID, selectedDayValue, 8, 9);
      let startSignIndex0011 = 8;
      let endSignIndex0011 = 9;
      if (months[11].transferDate < selectedDayValue) {
        // console.log('current end SignInDex11 !' + endSignIndex11);

        let signID = endSignIndex0011;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[11].transferDate) {
        // console.log('current Start SignInDex11 !' + startSignIndex11);

        let signID = startSignIndex0011;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;




    case '0':
      // identifySign(monthArrayID, selectedDayValue, 9, 10);

      let startSignIndex0 = 9;
      let endSignIndex0 = 10;


      if (months[0].transferDate < selectedDayValue) {
        // console.log('current end SignInDex0 !' + endSignIndex0);

        let signID = endSignIndex0;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[0].transferDate) {
        // console.log('current Start SignInDex0 !' + startSignIndex0);

        let signID = startSignIndex0;
        // signArrayIdIndex(signID);
        return signID;
      }

      break;
      // return

    case '1':
      // identifySign(monthArrayID, selectedDayValue, 10, 11);
      let startSignIndex1 = 10;
      let endSignIndex1 = 11;
      if (months[1].transferDate < selectedDayValue) {
        // console.log('current end SignInDex1 !' + endSignIndex1);

        let signID = endSignIndex1;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[1].transferDate) {
        // console.log('current Start SignInDex1 !' + startSignIndex1);

        let signID = startSignIndex1;
        // signArrayIdIndex(signID);
        return signID;
      }

      break;
      // return;

    case '2':
      // identifySign(monthArrayID, selectedDayValue, 11, 0);
      let startSignIndex2 = 11;
      let endSignIndex2 = 0;
      if (months[2].transferDate < selectedDayValue) {
        // console.log('current end SignInDex2 !' + endSignIndex2);

        let signID = endSignIndex2;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[2].transferDate) {
        // console.log('current Start SignInDex2 !' + startSignIndex2);

        let signID = startSignIndex2;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '3':
      // identifySign(monthArrayID, selectedDayValue, 0, 1);
      let startSignIndex3 = 0;
      let endSignIndex3 = 1;
      if (months[3].transferDate < selectedDayValue) {
        // console.log('current end SignInDex3 !' + endSignIndex3);

        let signID = endSignIndex3;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[3].transferDate) {
        // console.log('current Start SignInDex3 !' + startSignIndex3);

        let signID = startSignIndex3;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '4':
      // identifySign(monthArrayID, selectedDayValue, 1, 2);
      let startSignIndex4 = 1;
      let endSignIndex4 = 2;
      if (months[4].transferDate < selectedDayValue) {
        // console.log('current end SignInDex4 !' + endSignIndex4);

        let signID = endSignIndex4;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[4].transferDate) {
        // console.log('current Start SignInDex !4' + startSignIndex4);

        let signID = startSignIndex4;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '5':
      // identifySign(monthArrayID, selectedDayValue, 2, 3);
      let startSignIndex5 = 2;
      let endSignIndex5 = 3;
      if (months[5].transferDate < selectedDayValue) {
        // console.log('current end SignInDex5 !' + endSignIndex5);

        let signID = endSignIndex5;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[5].transferDate) {
        // console.log('current Start SignInDex5 !' + startSignIndex5);

        let signID = startSignIndex5;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '6':
      // identifySign(monthArrayID, selectedDayValue, 3, 4);
      let startSignIndex6 = 3;
      let endSignIndex6 = 4;
      if (months[6].transferDate < selectedDayValue) {
        // console.log('current end SignInDex 6!' + endSignIndex6);

        let signID = endSignIndex6;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[6].transferDate) {
        // console.log('current Start SignInDex6 !' + startSignIndex6);

        let signID = startSignIndex6;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '7':
      // identifySign(monthArrayID, selectedDayValue, 4, 5);
      let startSignIndex7 = 4;
      let endSignIndex7 = 5;
      if (months[7].transferDate < selectedDayValue) {
        // console.log('current end SignInDex7 !' + endSignIndex7);

        let signID = endSignIndex7;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[7].transferDate) {
        // console.log('current Start SignInDex7 !' + startSignIndex7);

        let signID = startSignIndex7;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '8':
      // identifySign(monthArrayID, selectedDayValue, 5, 6);
      let startSignIndex8 = 5;
      let endSignIndex8 = 6;
      if (months[8].transferDate < selectedDayValue) {
        // console.log('current end SignInDex8 !' + endSignIndex8);

        let signID = endSignIndex8;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[8].transferDate) {
        // console.log('current Start SignInDex8 !' + startSignIndex8);

        let signID = startSignIndex8;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '9':
      // identifySign(monthArrayID, selectedDayValue, 6, 7);
      let startSignIndex9 = 6;
      let endSignIndex9 = 7;
      if (months[9].transferDate < selectedDayValue) {
        // console.log('current end SignInDex9 !' + endSignIndex9);

        let signID = endSignIndex9;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[9].transferDate) {
        // console.log('current Start SignInDex9 !' + startSignIndex9);

        let signID = startSignIndex9;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '10':
      // identifySign(monthArrayID, selectedDayValue, 7, 8);
      let startSignIndex10 = 7;
      let endSignIndex10 = 8;
      if (months[10].transferDate < selectedDayValue) {
        // console.log('current end SignInDex10 !' + endSignIndex10);

        let signID = endSignIndex10;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[10].transferDate) {
        // console.log('current Start SignInDex10 !' + startSignIndex10);

        let signID = startSignIndex10;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case '11':
      // identifySign(monthArrayID, selectedDayValue, 8, 9);
      let startSignIndex11 = 8;
      let endSignIndex11 = 9;
      if (months[11].transferDate < selectedDayValue) {
        // console.log('current end SignInDex11 !' + endSignIndex11);

        let signID = endSignIndex11;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[11].transferDate) {
        // console.log('current Start SignInDex11 !' + startSignIndex11);

        let signID = startSignIndex11;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;

    case 0:
      // identifySign(monthArrayID, selectedDayValue, 9, 10);

      let startSignIndex00 = 9;
      let endSignIndex00 = 10;

      if (months[0].transferDate < selectedDayValue) {
        // console.log('current end SignInDex00 !' + endSignIndex00);

        let signID = endSignIndex00;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[0].transferDate) {
        // console.log('current Start SignInDex00 !' + startSignIndex00);

        let signID = startSignIndex00;
        // signArrayIdIndex(signID);
        return signID;
      }

      break;
      // return

    case 1:
      // identifySign(monthArrayID, selectedDayValue, 10, 11);
      let startSignIndex01 = 10;
      let endSignIndex01 = 11;
      if (months[1].transferDate < selectedDayValue) {
        // console.log('current end SignInDex01 !' + endSignIndex01);

        let signID = endSignIndex01;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[1].transferDate) {
        // console.log('current Start SignInDex01 !' + startSignIndex01);

        let signID = startSignIndex01;
        // signArrayIdIndex(signID);
        return signID;
      }

      break;
      // return;

    case 2:
      // identifySign(monthArrayID, selectedDayValue, 11, 0);
      let startSignIndex02 = 11;
      let endSignIndex02 = 0;
      if (months[2].transferDate < selectedDayValue) {
        // console.log('current end SignInDex02 !' + endSignIndex02);

        let signID = endSignIndex02;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[2].transferDate) {
        // console.log('current Start SignInDex02 !' + startSignIndex02);

        let signID = startSignIndex02;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 3:
      // identifySign(monthArrayID, selectedDayValue, 0, 1);
      let startSignIndex03 = 0;
      let endSignIndex03 = 1;
      if (months[3].transferDate < selectedDayValue) {
        // console.log('current end SignInDex03 !' + endSignIndex03);

        let signID = endSignIndex03;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[3].transferDate) {
        // console.log('current Start SignInDex03 !' + startSignIndex03);

        let signID = startSignIndex03;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 4:
      // identifySign(monthArrayID, selectedDayValue, 1, 2);
      let startSignIndex04 = 1;
      let endSignIndex04 = 2;
      if (months[4].transferDate < selectedDayValue) {
        // console.log('current end SignInDex04 !' + endSignIndex04);

        let signID = endSignIndex04;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[4].transferDate) {
        // console.log('current Start SignInDex !04' + startSignIndex04);

        let signID = startSignIndex04;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 5:
      // identifySign(monthArrayID, selectedDayValue, 2, 3);
      let startSignIndex05 = 2;
      let endSignIndex05 = 3;
      if (months[5].transferDate < selectedDayValue) {
        // console.log('current end SignInDex05 !' + endSignIndex05);

        let signID = endSignIndex05;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[5].transferDate) {
        // console.log('current Start SignInDex05 !' + startSignIndex05);

        let signID = startSignIndex05;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 6:
      // identifySign(monthArrayID, selectedDayValue, 3, 4);
      let startSignIndex06 = 3;
      let endSignIndex06 = 4;
      if (months[6].transferDate < selectedDayValue) {
        // console.log('current end SignInDex06!' + endSignIndex06);

        let signID = endSignIndex06;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[6].transferDate) {
        // console.log('current Start SignInDex06 !' + startSignIndex06);

        let signID = startSignIndex06;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 7:
      // identifySign(monthArrayID, selectedDayValue, 4, 5);
      let startSignIndex07 = 4;
      let endSignIndex07 = 5;
      if (months[7].transferDate < selectedDayValue) {
        // console.log('current end SignInDex07 !' + endSignIndex07);

        let signID = endSignIndex07;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[7].transferDate) {
        // console.log('current Start SignInDex07 !' + startSignIndex07);

        let signID = startSignIndex07;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 8:
      // identifySign(monthArrayID, selectedDayValue, 5, 6);
      let startSignIndex08 = 5;
      let endSignIndex08 = 6;
      if (months[8].transferDate < selectedDayValue) {
        // console.log('current end SignInDex08 !' + endSignIndex08);

        let signID = endSignIndex08;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[8].transferDate) {
        // console.log('current Start SignInDex08 !' + startSignIndex08);

        let signID = startSignIndex08;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 9:
      // identifySign(monthArrayID, selectedDayValue, 6, 7);
      let startSignIndex09 = 6;
      let endSignIndex09 = 7;
      if (months[9].transferDate < selectedDayValue) {
        // console.log('current end SignInDex09 !' + endSignIndex09);

        let signID = endSignIndex09;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[9].transferDate) {
        // console.log('current Start SignInDex09 !' + startSignIndex09);

        let signID = startSignIndex09;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 10:
      // identifySign(monthArrayID, selectedDayValue, 7, 8);
      let startSignIndex010 = 7;
      let endSignIndex010 = 8;
      if (months[10].transferDate < selectedDayValue) {
        // console.log('current end SignInDex010 !' + endSignIndex010);

        let signID = endSignIndex010;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[10].transferDate) {
        // console.log('current Start SignInDex010 !' + startSignIndex010);

        let signID = startSignIndex010;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;
    case 11:
      // identifySign(monthArrayID, selectedDayValue, 8, 9);
      let startSignIndex011 = 8;
      let endSignIndex011 = 9;
      if (months[11].transferDate < selectedDayValue) {
        // console.log('current end SignInDex011 !' + endSignIndex011);

        let signID = endSignIndex011;
        return signID;
      }

      // Set startsign
      if (selectedDayValue <= months[11].transferDate) {
        // console.log('current Start SignInDex011 !' + startSignIndex011);

        let signID = startSignIndex011;
        // signArrayIdIndex(signID);
        return signID;
      }
      break;
      // return;




    case 'complete':
      console.log('hit complete !');

      break;

    default:
      return 'invalid today2SignArrayICCalculator data entered';
      // break;
  }

  // function identifySign(
  //   selectedValue,
  //   selectedDayValue,
  //   startSignIndex,
  //   endSignIndex,
  //  ) {
  //   if (months[selectedValue].transferDate < selectedDayValue) {
  //     console.log('current end SignInDex !' + endSignIndex);

  //     let signID = endSignIndex;
  //     return endSignIndex;
  //   }

  //   // Set startsign
  //   if (selectedDayValue <= months[selectedValue].transferDate) {
  //     console.log('current Start SignInDex !' + startSignIndex);

  //     let signID = startSignIndex;
  //     // signArrayIdIndex(signID);
  //     return startSignIndex;
  //   }
  // }
};

// getSignIndex(IndexArrayNumber);

// }
