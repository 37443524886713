// import logo from './logo.svg';
import React, {useState, useEffect, Component} from 'react';
import axios from 'axios';

// import React from 'react';
import './App.css';
import Today2SignArrayIDCalculator from './components/Today2SignArrayIDCalculator';



const baseURL = "https://jsonplaceholder.typicode.com/posts/1";


// const bosAPI = 'http://localhost:3003/all/';
//const bosAPI = 'https://bosastrology.com/bosapi/all/';
const bosAPI = 'https://bosastrology.com/bosapi/calendar/2021/';
// const bosAPI = process.env.bosAPI;


function App() {
  //Date info from the userslocal Machine config
  // const timeIs = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timeIs, setTimeIs] = React.useState( Intl.DateTimeFormat().resolvedOptions().timeZone) ;
  const [currentTime, setCurrentTime] = React.useState( Date().toLocaleString());
  const [date, setDate] = React.useState( new Date().getDate()); //To get the Current Date
  const [month, setMonth] = React.useState( new Date().getMonth() + 1); //To get the Current Month
  const [year, setYear] = React.useState( new Date().getFullYear()); //To get the Current Year
  const [hours, setHours] = React.useState( new Date().getHours()); //To get the Current Hours
  const [min, setMin] = React.useState( new Date().getMinutes()); //To get the Current Minutes
  var sec = new Date().getSeconds(); //To get the Current Seconds

  // const [currentSignIndex, setCurrentSignIndex] = useState(0);
  const [post, setPost] = React.useState(null);
  const [monthCollection, setMonthCollection] = React.useState([null]);
  const [todaySignIs, setTodaySignIs] = React.useState(0);
  const [signName, setSignName] = React.useState(null);
  const [signElement, setElement] = React.useState(null);
  const [signQuality, setQuality] = React.useState(null);
  const [signHouse, setHouse] = React.useState(null);
  const [signGlyph, setGlyph] = React.useState(null);
  const [signIcon, setIcon] = React.useState(null);
  const [signPlanet, setPlanet] = React.useState(null);
  const [signPlanet2, setPlanet2] = React.useState(null);




  const date3 = new Date();  // 2009-11-10
  const monthName = date3.toLocaleString('default', { month: 'long' });
  console.log('month is', monthName, date3);


  // let TodayData = Today2SignArrayIDCalculator.signArrayIdIndex(
  //   monthName,
  //   date,
  //   monthCollection
  // );




  // const date3 = new Date();  // 2009-11-10
  // const month3 = date3.toLocaleString('default', { month: 'long' });
  // console.log('month is', month3, date3);








  React.useEffect(() => {

    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });

    axios.get(bosAPI).then((response) => {
      setMonthCollection(response.data.months);
      let calendarData = response.data.months;
      let zodiacData = response.data.signs;

      const date1 = new Date().getDate(); //To get the Current Date
      const date2 = new Date(); //To get the Current Date
      // const monthNameIs = JSON.stringify(date2.toLocaleString('default', { month: 'long' }));
      const monthNameIs = date2.toLocaleString('default', { month: 'long' });

      let signArrayIdIndex = Today2SignArrayIDCalculator.signArrayIdIndex(
        monthNameIs,
        date1,
        calendarData
      );

      setTodaySignIs(signArrayIdIndex);
      setSignName(zodiacData[signArrayIdIndex].signName)
      setElement(zodiacData[signArrayIdIndex].elementDisplayCharacter);
      setQuality(zodiacData[signArrayIdIndex].qualityDisplayCharacter);
      setHouse(zodiacData[signArrayIdIndex].houseDisplayCharacter);
      setGlyph(zodiacData[signArrayIdIndex].glyphDisplayCharacter);
      setIcon(zodiacData[signArrayIdIndex].iconDisplayCharacter);
      setPlanet(zodiacData[signArrayIdIndex].planetDisplayCharacter);
          
      console.log('returned', signArrayIdIndex, zodiacData[signArrayIdIndex])
    });


  }, []);

  if (!post) return null;


  return (
    <div className="App">
      {/* <div className="BOS">M</div> */}
      <p>Today is:</p>
      <h1>{monthName} {date}, {year}</h1>
      <p className="BOS iconSize">{signIcon}</p>
      <h2>{ signName }</h2>
      <div className="BOS inline">
      <p>{signGlyph}</p>
      <p>{signQuality}</p>
      <p>{signElement}</p>
        <p>{signHouse}</p>
        <p>{signPlanet}</p>
        <p>{signPlanet2}</p>
      </div>
      {/* <hr></hr>
      {timeIs}
      <hr></hr>
      <p>Current Time : {currentTime}</p> 
      <p>Month : {month}</p>
      <p>Date : {date}</p>
      <p>Year : {year}</p>
      <p>Hour : {hours}</p>
      <p>Minute : {min}</p>
      <p>Seconds : {sec}</p>*/}

    </div>
  );
}

export default App;
